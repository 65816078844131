import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title, jsonLD, isLocationPage }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const {
          careersPageSeoDescription,
          cateringPageSeoDescription,
          collectionListSeoDescription,
          contactPageSeoDescription,
          ecommerceHomePageSeoDescription,
          franchisePageSeoDescription,
          locationsPageSeoDescription,
          ourStorySeoDescription,
          pressPageSeoDescription,
          safeServicePageSeoDescription,
          seoDescription,
          careersPageSeoTitle,
          cateringPageSeoTitle,
          collectionListSeoTitle,
          contactPageSeoTitle,
          ecommerceHomePageSeoTitle,
          franchisePageSeoTitle,
          locationsPageSeoTitle,
          ourStorySeoTitle,
          pressPageSeoTitle,
          safeServicePageSeoTitle,
        } = data.headerData;
        var metaDescription, metaTitle;
        if (typeof window === 'undefined') {
          metaDescription = description || seoDescription;
          metaTitle = title;
        } else if (isLocationPage) {
          metaDescription = description;
          metaTitle = title;
        } else if (window.location.pathname.includes('careers')) {
          metaDescription = careersPageSeoDescription || seoDescription;
          metaTitle = careersPageSeoTitle || title;
        } else if (window.location.pathname.includes('catering')) {
          metaDescription = cateringPageSeoDescription || seoDescription;
          metaTitle = cateringPageSeoTitle || title;
        } else if (window.location.pathname.includes('collection-list')) {
          metaDescription = collectionListSeoDescription || seoDescription;
          metaTitle = collectionListSeoTitle || title;
        } else if (window.location.pathname.includes('contact')) {
          metaDescription = contactPageSeoDescription || seoDescription;
          metaTitle = contactPageSeoTitle || title;
        } else if (window.location.pathname.includes('shop')) {
          metaDescription = ecommerceHomePageSeoDescription || seoDescription;
          metaTitle = ecommerceHomePageSeoTitle || title;
        } else if (window.location.pathname.includes('franchise')) {
          metaDescription = franchisePageSeoDescription || seoDescription;
          metaTitle = franchisePageSeoTitle || title;
        } else if (window.location.pathname.includes('locations')) {
          metaDescription = locationsPageSeoDescription || seoDescription;
          metaTitle = locationsPageSeoTitle || title;
        } else if (window.location.pathname.includes('our-story')) {
          metaDescription = ourStorySeoDescription || seoDescription;
          metaTitle = ourStorySeoTitle || title;
        } else if (window.location.pathname.includes('press')) {
          metaDescription = pressPageSeoDescription || seoDescription;
          metaTitle = pressPageSeoTitle || title;
        } else if (window.location.pathname.includes('safe-service')) {
          metaDescription = careersPageSeoDescription || seoDescription;
          metaTitle = careersPageSeoTitle || title;
        } else {
          metaDescription = description || seoDescription;
          metaTitle = title;
        }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              ...(process.env.GATSBY_ENV === 'staging'
                ? [
                    {
                      name: `robots`,
                      content: `noindex`,
                    },
                  ]
                : []),
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: `${metaTitle} | ${data.site.siteMetadata.title}`,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: 'facebook-domain-verification',
                content: 'psy585wvv2zngnc4bxniibr4fx1a1f',
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f)})(window,document,"script","dataLayer","GTM-M9FW7B8");`}</script>
            <script>{`
              !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '505568914702344');
              fbq('track', 'PageView');
            `}</script>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-764617497"></script>
            <script src="https://cdn.attn.tv/cousins/dtag.js"></script>
            <script>
              {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-764617497');`}
            </script>
            {jsonLD && <script type="application/ld+json">{jsonLD}</script>}
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    headerData: contentfulNavigation(title: { eq: "Main Site Header" }) {
      careersPageSeoDescription
      cateringPageSeoDescription
      collectionListSeoDescription
      contactPageSeoDescription
      ecommerceHomePageSeoDescription
      franchisePageSeoDescription
      locationsPageSeoDescription
      ourStorySeoDescription
      pressPageSeoDescription
      safeServicePageSeoDescription
      seoDescription
      careersPageSeoTitle
      cateringPageSeoTitle
      collectionListSeoTitle
      contactPageSeoTitle
      ecommerceHomePageSeoTitle
      franchisePageSeoTitle
      locationsPageSeoTitle
      ourStorySeoTitle
      pressPageSeoTitle
      safeServicePageSeoTitle
    }
  }
`;
